import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ls from 'local-storage';

import axios from '../lib/axios';

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    dashboard: {
      leagues: [],
      league_invites: [],
      series_seasons: [],
    },
    isLoggedIn: false,
    loading: false,
  },
  reducers: {
    authBegin: (auth) => {
      auth.loading = true;
    },
    authEnd: (auth) => {
      auth.loading = false;
    },
    logIn: (auth, action) => {
      auth.user = action.payload;
      auth.isLoggedIn = true;
    },
    // need BE route to delete token?
    logOut: (auth) => {
      auth.user = {};
      auth.leagues = [];
      auth.league_invites = [];
      auth.isLoggedIn = false;
    },
    getDashboard: (auth, action) => {
      auth.dashboard = action.payload;
    },
    declineLeagueInvite: (auth, action) => {
      auth.dashboard.league_invites = auth.dashboard.league_invites.filter((d) => d.uuid !== action.payload);
    },
  },
});

export const {
  authBegin,
  authEnd,
  logIn,
  logOut,
  getDashboard,
  declineLeagueInvite,
} = slice.actions;

// LOG_IN
export const startLogIn = (values, setSubmitting, navigate) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/login`, values);
    const { user } = response.data;
    ls('user', user);
    dispatch(logIn(user));
  } catch (error) {
    if (error.response?.status === 409) {
      ls('email', values.email);
      navigate('/two-factor');
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
    console.error(error.response);
    setSubmitting();
  }
  dispatch(authEnd());
};

// Sign Up
export const startSignUp = (values, setSubmitting, navigate) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/register`, values);
    navigate('/two-factor');
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(authEnd());
};

// Two Factor Authentication
export const startAuthenticate = (values, setSubmitting, navigate) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/authenticate`, values);
    if (response.data.user) {
      const { user } = response.data;
      ls.remove('email');
      await dispatch(logIn(user));
      navigate('/dashboard');
    } else {
      toast.warn('Something went wrong, Resend a new code');
      setSubmitting();
    }
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(authEnd());
};

// Resend Two Factor
export const startResendTwoFactor = (email) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/resend/two-factor`, { email });
    toast.success('New Code Sent');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(authEnd());
};

// LOG OUT
export const startLogOut = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/logout`);
    ls.remove('user');
    dispatch(logOut());
  } catch (error) {
    console.error(error.response);
    ls.remove('user');
    dispatch(logOut());
  }
  dispatch(authEnd());
};

// Send Forgot Password Email
export const startSendForgotPasswordEmail = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/reset-password-email`, payload);
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(authEnd());
};

// Reset Password
export const startResetPassword = (payload, setSubmitting, navigate) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/reset-password`, payload);
    ls.remove('user');
    toast.success('Password updated. Please log back in.');
    navigate('/login');
  } catch (error) {
    console.error(error.response);
    if (error.response?.status === 406) {
      toast.error('Password reset token expired. Please check your email for a new link.');
      navigate('/login');
    } else {
      setSubmitting();
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
  dispatch(authEnd());
};

// DASHBOARD
export const startGetDashboard = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/dashboard`);
    dispatch(getDashboard(response.data));
  } catch (error) {
    console.error(error.response);
  }
  dispatch(authEnd());
};

export const startAcceptLeagueInvite = (invite_uuid, league_team_name, navigate, setSubmitting) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/league-invites/accept/${invite_uuid}`, { league_team_name });
    navigate(`/league/${response.data.league_uuid}/league-home`);
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(authEnd());
};

export const startDeclineLeagueInvite = (invite_uuid) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/league-invites/decline/${invite_uuid}`);
    dispatch(declineLeagueInvite(invite_uuid));
    toast.warning('Declined Invite');
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(authEnd());
};

export const startMaintenanceCheck = async (navigate) => {
  try {
    const response = await axios.get(`${baseURL}/healthcheck`);
    if (response.status === 200) {
      navigate('/');
    }
  } catch (error) {
    console.error(error.response);
  }
};

export default slice.reducer;
