import React, { Suspense } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

import LeagueRouter from './LeagueRouter';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import Loading from '../components/reusable/Loading';
import Header from '../components/Header';
import Footer from '../components/Footer';
import lazyWithRetry from './lazyWithRetry';

const Landing = lazyWithRetry(() => import('../components/Landing'));
const Dashboard = lazyWithRetry(() => import('../components/dashboard/Dashboard'));
const LogIn = lazyWithRetry(() => import('../components/auth/Login'));
const SignUp = lazyWithRetry(() => import('../components/auth/SignUp'));
const TwoFactor = lazyWithRetry(() => import('../components/auth/TwoFactor'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));
const LandingRulesPage = lazyWithRetry(() => import('../components/rules/LandingRulesPage'));
const Draft = lazyWithRetry(() => import('../components/league/draft/Draft'));
const Contact = lazyWithRetry(() => import('../components/Contact'));
const PrivacyPolicy = lazyWithRetry(() => import('../components/PrivacyPolicy'));

const NotFoundPage = lazyWithRetry(() => import('../components/NotFoundPage'));
const MaintenancePage =  lazyWithRetry(() => import('../components/MaintenancePage'));

const AppRouter = () => {
  const { pathname } = useLocation();

  return (
    <div className='min-vh-100 d-flex flex-column justify-content-between'>
      <div>
        {pathname !== '/' && pathname !== '/maintenance' && !pathname.includes('draft') && <Header />}
        <Suspense fallback={<Loading main />}>
          <Routes>
            <Route path='/' element={<PublicRoute><Landing /></PublicRoute>} />
            <Route path='/dashboard' element={<PrivateRoute><main><Dashboard /></main></PrivateRoute>} />

            {/* AUTH */}
            <Route path='/login' element={<PublicRoute><main><LogIn /></main></PublicRoute>} />
            <Route path='/sign-up' element={<PublicRoute><main><SignUp /></main></PublicRoute>} />
            <Route path='/two-factor' element={<PublicRoute><main><TwoFactor /></main></PublicRoute>} />
            <Route path='/forgot-password' element={<PublicRoute><main><ForgotPassword /></main></PublicRoute>} />
            <Route path='/reset-password/:token?' element={<PublicRoute><main><ResetPassword /></main></PublicRoute>} />
            <Route path='/rules' element={<PublicRoute><main><LandingRulesPage /></main></PublicRoute>} />

            {/* LEAGUE */}
            <Route path='/league/:league_uuid/draft' element={<PrivateRoute><Draft /></PrivateRoute>} />
            <Route path='/league/:league_uuid/*' element={<PrivateRoute><main><LeagueRouter /></main></PrivateRoute>} />

            {/* OTHER */}
            <Route path='/contact' element={<main><Contact /></main>} />
            <Route path='/privacy-policy' element={<main><PrivacyPolicy /></main>} />

            <Route path='/maintenance' element={<MaintenancePage />} />
            <Route element={<main><NotFoundPage /></main>} />

          </Routes>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default AppRouter;
