import React, { Suspense } from 'react';

import { Modal } from 'react-bootstrap';

import Loading from '../reusable/Loading';
import lazyWithRetry from '../../routers/lazyWithRetry';

const F1Rules = lazyWithRetry(() => import('./F1Rules'));
const MotoGPRules = lazyWithRetry(() => import('./MotoGPRules'));
const ProMotocrossRules = lazyWithRetry(() => import('./ProMotocrossRules'));
const SupercrossRules = lazyWithRetry(() => import('./SupercrossRules'));
const NASCARRules = lazyWithRetry(() => import('./NASCARRules'));

const RulesModal = ({ show, onHide, seriesName }) => (
  <Modal show={show} onHide={onHide} size='xl'>
    <div className='p-2'>
      <div className='d-flex justify-content-end align-items-start'>
        <i className='fas fa-times pointer mt-2 mr-2' onClick={onHide} />
      </div>
      <Suspense fallback={<Loading main />}>
        {seriesName?.toUpperCase() === 'F1'.toUpperCase() && <F1Rules />}
        {seriesName?.toUpperCase() === 'MotoGP'.toUpperCase() && <MotoGPRules />}
        {seriesName?.toUpperCase() === 'Pro Motocross'.toUpperCase() && <ProMotocrossRules />}
        {seriesName?.toUpperCase() === 'Supercross'.toUpperCase() && <SupercrossRules />}
        {seriesName?.toUpperCase() === 'NASCAR'.toUpperCase() && <NASCARRules />}
      </Suspense>
    </div>
  </Modal>
);

export default RulesModal;
