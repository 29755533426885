import React from 'react';

import { NavLink } from 'react-router-dom';

const LeagueNav = ({ league_uuid }) => (
  <>
    {/* DESKTOP */}
    <nav className='d-none d-lg-flex'>
      <NavLink
        to={`/league/${league_uuid}/league-home`}
        className={({ isActive }) => `nav-element w-25 text-center hover-gray py-3 ${isActive ? 'selected' : ''}`}
      >
          LEAGUE HOME
      </NavLink>
      <NavLink
        to={`/league/${league_uuid}/my-team`}
        className={({ isActive }) => `nav-element w-25 text-center hover-gray py-3 ${isActive ? 'selected' : ''}`}
      >
          MY TEAM
      </NavLink>
      <NavLink
        to={`/league/${league_uuid}/matchup`}
        className={({ isActive }) => `nav-element w-25 text-center hover-gray py-3 ${isActive ? 'selected' : ''}`}
      >
          MATCHUP
      </NavLink>
      <NavLink
        to={`/league/${league_uuid}/roster`}
        className={({ isActive }) => `nav-element w-25 text-center hover-gray py-3 ${isActive ? 'selected' : ''}`}
      >
          ROSTER
      </NavLink>
    </nav>

    {/* MOBILE */}
    <nav className='league-nav-mobile d-flex d-lg-none fixed-bottom'>
      <NavLink
        to={`/league/${league_uuid}/league-home`}
        className={({ isActive }) => `league-nav-element-mobile w-25 d-flex justify-content-center align-items-start pt-2 ${isActive ? 'selected' : ''}`}
      >
        <img
          src='https://speed-league-fantasy.s3.us-west-1.amazonaws.com/leagueicon.svg'
          alt='League Icon'
          className='league-nav-img-mobile'
        />
      </NavLink>
      <NavLink
        to={`/league/${league_uuid}/my-team`}
        className={({ isActive }) => `league-nav-element-mobile w-25 d-flex justify-content-center align-items-start pt-2 ${isActive ? 'selected' : ''}`}
      >
        <img
          src='https://speed-league-fantasy.s3.us-west-1.amazonaws.com/myteamicon.svg'
          alt='My Team Icon'
          className='league-nav-img-mobile'
        />
      </NavLink>
      <NavLink
        to={`/league/${league_uuid}/matchup`}
        className={({ isActive }) => `league-nav-element-mobile w-25 d-flex justify-content-center align-items-start pt-2 ${isActive ? 'selected' : ''}`}
      >
        <img
          src='https://speed-league-fantasy.s3.us-west-1.amazonaws.com/matchupicon.svg'
          alt='Matchup Icon'
          className='league-nav-img-mobile'
        />
      </NavLink>
      <NavLink
        to={`/league/${league_uuid}/roster`}
        className={({ isActive }) => `league-nav-element-mobile w-25 d-flex justify-content-center align-items-start pt-2 ${isActive ? 'selected' : ''}`}
      >
        <img
          src='https://speed-league-fantasy.s3.us-west-1.amazonaws.com/rostericon.svg'
          alt='Racers Icon'
          className='league-nav-img-mobile'
        />
      </NavLink>
    </nav>
  </>
);

export default LeagueNav;
