import React, { useEffect } from 'react';

import ls from 'local-storage';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import configureStore from './redux/configureStore';
import { logIn } from './redux/auth';
import AppRouter from './routers/AppRouter';

import 'react-toastify/dist/ReactToastify.css';

export const store = configureStore();

const App = () => {
  const ScrollToTop = () => {
    const { pathname, hash } = useLocation();
    useEffect(() => {
      if (!hash) { // Block auto scroll when executing hash scrolling elsewhere (League Chat)
        window.scrollTo(0, 0);
      }
    }, [pathname]);
    return null;
  };

  if (ls('user')) {
    const user = ls('user');
    console.log('User: ', user);
    store.dispatch(logIn(user));
  }

  if (ls('authorization')) {
    ls.remove('authorization');
  }

  return (
    <Provider store={store}>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme='colored'
      />
      <Router>
        <ScrollToTop />
        <AppRouter />
      </Router>
    </Provider>
  );
};

export default App;
