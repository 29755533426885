import { createSlice } from '@reduxjs/toolkit';
import { isBefore, isAfter, add } from 'date-fns';
import { toast } from 'react-toastify';

import { startGetDashboard } from './auth';
import axios from '../lib/axios';

const initialState = {
  uuid: '',
  is_host: false,
  league_name: '',
  series_name: '',
  season: '',
  iconType: '',
  draft_ready: false,
  is_drafted: false,
  eventInProgress: true,
  league_chats: {
    loading: false,
    messages: [],
  },
  activity_logs: [],
  league_members: [],
  onlineMembers: [],
  league_invites: [],
  classes: [],
  roster: { data: [] },
  events: [],
  selectedEvent: {},
  loading: false,
};

const slice = createSlice({
  name: 'league',
  initialState,
  reducers: {
    leagueBegin: (league) => {
      league.loading = true;
    },
    leagueEnd: (league) => {
      league.loading = false;
    },
    clearLeague: () => initialState,
    getLeague: (league, action) => {
      const { payload } = action;
      const memberUuid = payload.league_members.find((m) => m.is_user).uuid;

      let iconType = 'road';
      if (payload.series_name.toLowerCase().includes('supercross') || payload.series_name.toLowerCase().includes('motocross')) {
        iconType = 'dirt';
      }

      let foundCurrentEvent = false;
      let isCurrentEventChampionship = false;
      let eventInProgress = false;
      const events = payload.events
        ? payload.events.map((e, i) => {
          let isCurrentEvent = false;
          if (!foundCurrentEvent && (!e.is_finished || payload.events.length - 1 === i)) {
            isCurrentEvent = true;
            foundCurrentEvent = true;
            if (payload.events.length - 1 === i) isCurrentEventChampionship = true;
          }
          if (!eventInProgress &&
          isAfter(new Date(), new Date(e.date)) &&
          (isBefore(new Date(), add(new Date(e.date), { hours: 24 })) ||
          (e.is_started && !e.is_finished))) {
            eventInProgress = true;
          }
          return {
            ...e,
            isCurrentEvent,
            matchups: !e.matchups
              ? null
              : e.matchups.map((m) => {
                const members = [
                  {
                    uuid: m.member1_uuid,
                    total_points: m.member1_total_points,
                    is_winner: m.member1_uuid === m.winner_member_uuid,
                  },
                  {
                    uuid: m.member2_uuid,
                    total_points: m.member2_total_points,
                    is_winner: m.member2_uuid === m.winner_member_uuid,
                  },
                ];

                members.sort((a) => (a.uuid === memberUuid ? -1 : 1));

                return {
                  uuid: m.uuid,
                  members,
                };
              }).sort((a) => (a.members.find((m) => m.uuid === memberUuid) ? -1 : 1)),
          };
        })
        : [];

      const league_members = payload.league_members.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      return {
        ...league,
        ...payload,
        iconType,
        eventInProgress,
        isCurrentEventChampionship,
        isChampionshipComplete: events[events.length - 1]?.is_finished,
        league_members,
        events,
        loading: false,
      };
    },
    getLeagueChatsLogs: (league, action) => {
      league.activity_logs = action.payload.activity_logs.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      league.league_chats = {
        loading: false,
        messages: action.payload.league_chats.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),
      };
    },
    updateOnlineMembers: (league, action) => {
      league.onlineMembers = action.payload;
    },
    updateTeamDetails: (league, action) => {
      league.league_members = league.league_members.map((m) => (m.uuid === action.payload.uuid
        ? {
          ...m,
          team_name: action.payload.league_team_name,
          team_icon: action.payload.league_team_icon,
        }
        : m));
    },
    getLeagueInvites: (league, action) => {
      league.league_invites = action.payload;
      league.loading = false;
    },
    updateNoSpoiler: (league, action) => {
      league.selectedEvent.no_spoiler = action.payload;
    },
    chatBegin: (league) => {
      league.league_chats.loading = true;
    },
    chatEnd: (league) => {
      league.league_chats.loading = false;
    },
    newChatMessage: (league, action) => {
      league.league_chats.messages.push(action.payload);
    },
    getMyTeam: (league, action) => {
      const memberIndex = league.league_members.findIndex((m) => m.is_user);
      league.league_members[memberIndex].team = action.payload;
    },
    getMatchup: (league, action) => {
      const matchups = action.payload.matchups.map((d) => {
        const members = d.members.sort((a) => (a.is_user ? -1 : 1));
        return {
          ...d,
          members,
        };
      }).sort((a) => (a.members.find((m) => m.is_user) ? -1 : 1));
      league.selectedEvent = {
        ...action.payload,
        matchups,
      };
    },
    getRoster: (league, action) => {
      league.roster = {
        class_uuid: action.payload.class_uuid,
        data: action.payload.data.sort((a, b) => {
          if (!a.speed_league_rank) {
            return 1;
          } else if (!b.speed_league_rank) {
            return -1;
          }
          return a.speed_league_rank - b.speed_league_rank;
        }),
      };
    },
  },
});

const {
  leagueBegin,
  leagueEnd,
  clearLeague,
  getLeague,
  getLeagueChatsLogs,
  updateOnlineMembers,
  updateTeamDetails,
  getLeagueInvites,
  updateNoSpoiler,
  chatBegin,
  chatEnd,
  newChatMessage,
  getMyTeam,
  getMatchup,
  getRoster,
} = slice.actions;



// LEAGUE
export const startGetLeague = (league_uuid) => async (dispatch, getState) => {
  if (getState().league.uuid && getState().league.uuid !== league_uuid) {
    dispatch(clearLeague());
  }
  dispatch(leagueBegin());
  try {
    const response = await axios.get(`${baseURL}/leagues/${league_uuid}`);
    dispatch(getLeague(response.data));
  } catch (error) {
    console.error(error);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



export const startCreateLeague = (payload, navigate, setSubmitting) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    const response = await axios.post(`${baseURL}/leagues`, payload);
    navigate(`/league/${response.data.uuid}/league-home`);
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



export const startUpdateLeague = (league_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    await axios.put(`${baseURL}/leagues/${league_uuid}`, payload);
    await dispatch(startGetDashboard());
    toggle();
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



export const startScheduleDraft = (payload, league_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    await axios.put(`${baseURL}/leagues/${league_uuid}`, payload);
    dispatch(startGetLeague(league_uuid));
    toggle();
    toast.success('Draft settings saved');
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



export const startUpdateTeamDetails = (league_member_uuid, payload, onHide, setSubmitting) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    const response = await axios.put(`${baseURL}/league-members/${league_member_uuid}`, payload);
    dispatch(updateTeamDetails(response.data));
    onHide();
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



export const startGetLeagueChatsLogs = (league_uuid) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    const response = await axios.get(`${baseURL}/leagues/chats-logs/${league_uuid}`);
    dispatch(getLeagueChatsLogs(response.data));
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



// LEAGUE INVITES
export const startGetLeagueInvites = (league_uuid) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    const response = await axios.get(`${baseURL}/league-invites/${league_uuid}`);
    dispatch(getLeagueInvites(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(leagueEnd());
    toast.error('Something went wrong. Please try again.');
  }
};



export const startSendLeagueInvite = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    await axios.post(`${baseURL}/league-invites`, payload);
    dispatch(startGetLeagueInvites(payload.league_uuid));
    toggle();
    toast.success('Sent');
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong. Please try again.');
    setSubmitting();
  }
  dispatch(leagueEnd());
};



export const startDeleteLeagueInvite = (invite_uuid, league_uuid) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    await axios.delete(`${baseURL}/league-invites/${invite_uuid}`);
    dispatch(startGetLeagueInvites(league_uuid));
    toast.warning('Invite Deleted');
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



export const startUpdateNoSpoiler = (league_member_uuid, payload) => async (dispatch) => {
  dispatch(leagueBegin());
  dispatch(updateNoSpoiler(payload));
  // Remove results_available from payload
  const cleanPayload = payload.map((d) => ({ class_name: d.class_name, show_results: d.show_results }));
  try {
    await axios.put(`${baseURL}/league-members/${league_member_uuid}`, { no_spoiler: JSON.stringify(cleanPayload) });
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



// LEAGUE CHATS
export const startSendChatMessage = (payload, socket, clearChatField) => async (dispatch, getState) => {
  dispatch(chatBegin());
  try {
    await axios.post(`${baseURL}/league-chats`, payload);
    const sender_uuid = getState().league.league_members.find((d) => d.is_user).uuid;
    const emitPayload = {
      content: payload.content,
      sender_uuid,
      created_at: payload.created_at,
    };
    socket.emit('send-message', emitPayload);
    clearChatField();
    dispatch(newChatMessage(emitPayload));
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(chatEnd());
};



// MY TEAM
export const startGetMyTeam = (league_member_uuid) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    const response = await axios.get(`${baseURL}/league-members/${league_member_uuid}/team`);
    dispatch(getMyTeam(response.data));
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



// UPDATE PREDICTIONS
export const startUpdatePredictions = (league_uuid, league_member_uuid, payload, toggle, setSubmitting) => async (dispatch, getState) => {
  if (checkIsEventInProgress(getState)) {
    await dispatch(startGetLeague(league_uuid));
    toggle();
    setSubmitting();
    toast.error('Unable to complete transaction: Event in progress.');
  } else {
    dispatch(leagueBegin());
    try {
      await axios.put(`${baseURL}/league-members-rosters/predictions/${league_uuid}`, { payload });
      dispatch(startGetMyTeam(league_member_uuid));
      setSubmitting();
      toggle();
      toast.success('Predictions Updated');
    } catch (error) {
      setSubmitting();
      console.error(error.response);
      toast.error('Something went wrong. Please try again.');
    }
  }
  dispatch(leagueEnd());
};



// MATCHUP
export const startGetMatchup = (event_uuid, league_uuid) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    const response = await axios.get(`${baseURL}/matchups/event/${event_uuid}/${league_uuid}`);
    dispatch(getMatchup(response.data));
  } catch (error) {
    console.error(error);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



// ROSTER
export const startGetRoster = (class_uuid, league_uuid) => async (dispatch) => {
  dispatch(leagueBegin());
  try {
    const response = await axios.get(`${baseURL}/rosters/${league_uuid}/${class_uuid}`);
    dispatch(getRoster({ class_uuid, data: response.data }));
  } catch (error) {
    console.error(error.response);
    dispatch(getRoster({ data: [] }));
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(leagueEnd());
};



export const startRosterTransaction = (payload, toggle, setSubmitting) => async (dispatch, getState) => {
  if (checkIsEventInProgress(getState)) {
    await dispatch(startGetLeague(payload.league_uuid));
    setSubmitting();
    toggle();
    toast.error('Unable to complete transaction: Event in progress.');
  } else {
    try {
      await axios.post(`${baseURL}/league-members-rosters/transaction`, payload);
      setSubmitting();
      toggle();
      await dispatch(startGetRoster(payload.class_uuid, getState().league.uuid));
      await dispatch(startGetMyTeam(getState().league.league_members.find((m) => m.is_user).uuid));
      toast.success('Transaction Successful');
    } catch (error) {
      setSubmitting();
      console.error(error.response);
      toast.error('Something went wrong. Please try again.');
    }
  }
};



// LOCAL FUNCTIONS
const checkIsEventInProgress = (getState) => {
  const { events } = getState().league;
  for (let i = 0; i < events.length; i++) {
    const e = events[i];
    if (isAfter(new Date(), new Date(e.date)) && (isBefore(new Date(), add(new Date(e.date), { hours: 24 })) || (e.is_started && !e.is_finished))) {
      return true;
    }
  }
  return false;
};

export { newChatMessage, updateOnlineMembers };
export default slice.reducer;
