import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, matchPath } from 'react-router-dom';

import { startLogOut } from '../redux/auth';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const league = useSelector((state) => state.league);
  const { pathname } = useLocation();

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  CustomToggle.displayName = 'CustomToggle';

  return (
    <header className='d-flex align-items-center px-3 bg-black-gradient'>
      <div className='d-flex align-items-center justify-content-between w-100'>
        <div className='logo-cont d-flex'>
          <Link to='/'>
            <img
              src='/images/speed-league-logo.svg'
              alt='Speed League Logo'
              className='header-logo pointer'
            />
          </Link>
        </div>
        <div className='title-cont text-center overflow px-2'>
          {matchPath({ path: '/dashboard', end: true }, pathname) && (
            <>
              <p className='overflow'>DASHBOARD</p>
              <p className='header-series text-gray overflow'>Welcome back, {auth.user.first_name}</p>
            </>
          )}
          {matchPath({ path: '/league', end: false }, pathname) && (
            <>
              <Link to={`/league/${league.uuid}/league-home`}>
                <p className='d-inline overflow clickable'>{league.league_name.toUpperCase()}</p>
              </Link>

              <p className='header-series text-gray overflow'>{`${league.series_name} ${league.season}`}</p>
            </>
          )}
        </div>
        <div className='i-cont d-flex justify-content-end'>
          {auth.isLoggedIn && (
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <i className='fas fa-user-circle hover-gray pointer' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='text-center'>
                <Dropdown.Item onClick={() => navigate('/')}>Dashboard</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => dispatch(startLogOut())}>
                  <span><span className='fas fa-sign-out-alt mr-2' />Log Out</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
