import React from 'react';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';

import useToggle from '../lib/useToggle';
import RulesModal from './rules/RulesModal';

const Footer = () => {
  const { pathname } = useLocation();
  const series_name = useSelector((state) => state.league.series_name);

  const [isRulesOpen, toggleRules] = useToggle();

  return (
    <footer className='text-center mt-5'>

      {matchPath({ path: '/league', end: false }, pathname) &&
        <p className='clickable pointer py-2' onClick={toggleRules}>LEAGUE RULES</p>
      }

      <Link to='/contact' className='small-text text-gray my-2 mx-3'>Contact Us</Link>
      <Link to='/privacy-policy' className='small-text text-gray my-2 mx-3'>Privacy Policy</Link>
      <p className='small-text text-gray py-2'>&copy; Speed League Fantasy {format(new Date(), 'yyyy')}</p>

      {matchPath({ path: '/league', end: false }, pathname) && !matchPath({ path: '/league/:league_uuid/draft', end: true }, pathname) && <div className='mobile-nav-offset d-lg-none' />}

      <RulesModal show={isRulesOpen} onHide={toggleRules} seriesName={series_name} />

    </footer>
  );
};

export default Footer;
