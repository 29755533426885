import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './auth';
import league from './league';
import racers from './racers';

export default () => {
  const reducer = combineReducers({
    auth,
    league,
    racers,
  });
  return configureStore({
    reducer,
    middleware: [
      ...getDefaultMiddleware(),
    ],
    devTools: environment !== 'production',
  });
};
