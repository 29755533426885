import React, { Suspense, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';

import { newChatMessage, updateOnlineMembers } from '../redux/league';

// import AdBanner from '../components/AdBanner';
import LeagueNav from '../components/league/LeagueNav';
import Loading from '../components/reusable/Loading';
import lazyWithRetry from './lazyWithRetry';

const LeagueHome = lazyWithRetry(() => import('../components/league/LeagueHome'));
const MyTeam = lazyWithRetry(() => import('../components/league/MyTeam'));
const Matchup = lazyWithRetry(() => import('../components/league/Matchup'));
const Roster = lazyWithRetry(() => import('../components/league/Roster'));

const LeagueRouter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { league_uuid } = useParams();

  const reduxLeagueUuid = useSelector((state) => state.league.uuid);
  const league_members = useSelector((state) => state.league.league_members);
  const league_member_uuid = league_members.find((d) => d.is_user)?.uuid;

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (reduxLeagueUuid === league_uuid && league_member_uuid && !socket) {
      setSocket(io.connect(socketURL, {
        query: {
          room: `chat-${league_uuid}`,
          room_type: 'chat',
          league_member_uuid,
        },
      }));
    }
  }, [league_member_uuid]);

  useEffect(() => {
    if (socket) {
      socket.on('message-received', (data) => {
        dispatch(newChatMessage(data));
        const senderName = league_members.find((d) => d.uuid === data.sender_uuid)?.team_name;
        toast.info(`New chat message${senderName ? ` from ${senderName}` : ''}`, {
          icon: <span className='fas fa-envelope' />,
          onClick: () => navigate(`/league/${league_uuid}/league-home#league-chat`),
        });
      });
      socket.on('joined', (data) => dispatch(updateOnlineMembers(data)));
      socket.on('disconnected', (data) => dispatch(updateOnlineMembers(data)));
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  return (
    <Suspense fallback={<Loading main />}>
      {/* <AdBanner /> */}
      <LeagueNav league_uuid={league_uuid} />
      <Routes>
        <Route
          path='league-home'
          element={<LeagueHome league_uuid={league_uuid} socket={socket} />}
        />
        <Route path='my-team' element={<MyTeam league_uuid={league_uuid} />} />
        <Route path='matchup' element={<Matchup league_uuid={league_uuid} />}  />
        <Route path='matchup/:event_uuid' element={<Matchup league_uuid={league_uuid} />} />
        <Route path='roster' element={<Roster league_uuid={league_uuid} />} />
        <Route path='roster/:className' element={<Roster league_uuid={league_uuid} />} />
      </Routes>
    </Suspense>
  );
};

export default LeagueRouter;
