import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from '../lib/axios';

const initialState = {
  racerDetails: {
    loading: false,
    owner_team_name: null,
    sub_in: false,
    sub_out: false,
    results: [],
  },
  loading: false,
};

const slice = createSlice({
  name: 'racers',
  initialState,
  reducers: {
    racersBegin: (racers) => {
      racers.loading = true;
    },
    racersEnd: (racers) => {
      racers.loading = false;
    },

    racerDetailsBegin: (racers) => {
      racers.racerDetails.loading = true;
    },
    racerDetailsEnd: (racers) => {
      racers.racerDetails.loading = false;
    },
    getRacerDetails: (racers, action) => {
      racers.racerDetails = {
        ...action.payload,
        loading: false,
      };
    },
  },
});

const {
  // racersBegin,
  // racersEnd,

  racerDetailsBegin,
  racerDetailsEnd,
  getRacerDetails,
} = slice.actions;


// Get Racer Details
export const startGetRacerDetails = (roster_uuid, league_uuid) => async (dispatch) => {
  dispatch(racerDetailsBegin());
  try {
    const response = await axios.get(`${baseURL}/rosters/details/${league_uuid}/${roster_uuid}`);
    dispatch(getRacerDetails(response.data));
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong. Please try again.');
  }
  dispatch(racerDetailsEnd());
};



export default slice.reducer;
